/* eslint-disable */

// :scope support for querySelector
import 'element-qsa-scope'

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
import 'core-js/features/array/includes'

// Element.closest
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches = (Element.prototype as any).msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s: string) {
    let el = this as HTMLElement | null
    if (!document.documentElement.contains(el)) {
      return null
    }
    if (el) {
      do {
        if (el.matches(s)) {
          return el
        }
        el = el.parentElement
      } while (el !== null)
    }
    return null
  }
}

// https://stackoverflow.com/a/53327815
Promise.prototype.finally = Promise.prototype.finally || {
  finally (fn: any): any {
    const onFinally = (callback: any) => Promise.resolve(fn()).then(callback);
    return (this as any).then(
      (result: any) => onFinally(() => result),
      (reason: any) => onFinally(() => Promise.reject(reason))
    );
  }
}.finally;
